"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setStoreFromParams = void 0;
const terminQualitaetStore_1 = require("@/state/terminQualitaetStore");
const teamsStore_1 = require("@/state/teamsStore");
const lodash_1 = require("lodash");
function setStoreFromParams(routeParams) {
    if (routeParams) {
        console.log('setStoreFromParams::routeParams', routeParams);
        let newParams = Object.assign({}, routeParams);
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.leistungserbringer) {
            terminQualitaetStore_1.terminQualitaetStore.commit.setLeistungserbringerSelected(teamsStore_1.teamsStore.getters.teams.find(t => t.id === routeParams.leistungserbringer));
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.teams) {
            const teamIds = (0, lodash_1.split)(routeParams === null || routeParams === void 0 ? void 0 : routeParams.teams);
            const teams = [];
            for (const tid of teamIds) {
                const team = teamsStore_1.teamsStore.getters.teams.find(t => t.id === tid);
                if (team) {
                    teams.push(team);
                }
            }
            console.log('setStoreFromParams::team', teams);
            terminQualitaetStore_1.terminQualitaetStore.commit.setTeamsSelected(teams);
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.privat) {
            terminQualitaetStore_1.terminQualitaetStore.commit.setPrivatFilter(routeParams.privat);
        }
        if (routeParams === null || routeParams === void 0 ? void 0 : routeParams.search) {
            terminQualitaetStore_1.terminQualitaetStore.commit.setSearch(routeParams.search);
        }
        if ((routeParams === null || routeParams === void 0 ? void 0 : routeParams.von) && (routeParams === null || routeParams === void 0 ? void 0 : routeParams.bis)) {
            terminQualitaetStore_1.terminQualitaetStore.commit.setDate({
                startDate: routeParams.von,
                endDate: routeParams.bis,
            });
        }
        return newParams;
    }
}
exports.setStoreFromParams = setStoreFromParams;
