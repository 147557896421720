"use strict";

require("core-js/modules/es.object.define-property.js");

require("core-js/modules/es.number.constructor.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {},
  props: {
    absolut: Number,
    relative: Number,
    normalized: Number,
    formatter: String
  }
});