"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

exports["default"] = (0, vue_1.defineComponent)({
  components: {},
  props: {
    tq: {
      type: Object,
      "default": null
    },
    suffix: {
      type: String,
      "default": null
    }
  },
  computed: {
    anzahl: function anzahl() {
      var _a;

      return ((_a = this.tq) === null || _a === void 0 ? void 0 : _a["anzahl".concat(this.suffix)]) || 0;
    },
    dauer: function dauer() {
      var _a;

      return ((_a = this.tq) === null || _a === void 0 ? void 0 : _a["dauer".concat(this.suffix)]) || 0;
    },
    umsatzprognose: function umsatzprognose() {
      var _a;

      return ((_a = this.tq) === null || _a === void 0 ? void 0 : _a["umsatzprognose".concat(this.suffix)]) || 0;
    }
  }
});